import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { constantStyles } from "../constants/constantStyles";

const SearchBar = ({ onSearch, placeholderText, styleOverride}) => {
    const [query, setQuery] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();
        if (onSearch) onSearch(query);
    };

    return (
        <form style={{...styles.searchBar, ...styleOverride}} onSubmit={handleSearch}>
            <FaSearch style={styles.searchIcon} />
            <input
                type="text"
                placeholder={placeholderText ?? "what are you looking for?"}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                style={styles.input}
            />
        </form>
    );
};

export default SearchBar;

const styles = {
    searchBar: {
        display: "flex",
        alignItems: "center",
        background: "#EBEBEB",
        borderRadius: "25px",
        padding: "8px 12px",
        transition: "0.3s ease-in-out",
        height: "min-content",
    },
    searchIcon: {
        color: "#555",
        fontSize: "18px",
        marginRight: "8px",
    },
    input: {
        background: "#EBEBEB",
        border: "none",
        outline: "none",
        width: "100%",
        fontSize: "16px",
        padding: "5px",
        fontFamily: constantStyles.mainFontFamily,
    },
};