import React from 'react';
import Header from '../header/header';
import { constantStyles } from '../constants/constantStyles';

const Home = () => {
    return (
        <div>
            <Header />
            <div style={styles.mainContainer}>
                <div style={styles.mainImageContainer} onClick={() => window.location.href = '/featured'}>
                    <img style={styles.mainImage} src='/images/home_page/summer.jpg' alt="featured summer styles image" />
                </div>
            </div>
            <div style={styles.secondContainer}>
                <div style={styles.secondContainerImages}>
                    <div style={styles.imageContainer} onClick={() => window.location.href = '/women?categories=11'}>
                        <img style={{ ...styles.image, ...styles.imageLeft }} src='/images/home_page/women_denim.jpg' alt="women's denim image" />
                    </div>
                    <div style={styles.imageContainer} onClick={() => window.location.href = '/women?categories=19'}>
                        <img style={{ ...styles.image }} src='/images/home_page/dresses.jpg' alt="dresses image" />
                    </div>
                    <div style={styles.imageContainer} onClick={() => window.location.href = '/men?categories=20'}>
                        <img style={{ ...styles.image, ...styles.imageRight }} src='/images/home_page/men_outerwear.jpg' alt="men's outwear image" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

const styles = {
    mainContainer: {
        display: 'flex',
        margin: '20px 5% 40px 5%',
        justifyContent: 'center',
    },
    secondContainer: {
        margin: '20px 5% 20px 5%',
        display: 'flex',
        justifyContent: 'center',
    },
    secondContainerImages: {
        maxWidth: '1600px',
        justifyContent: 'space-between',
        gap: '2%',
        display: 'flex',
    },
    mainImageContainer: {
        cursor: 'pointer',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    mainImage: {
        maxWidth: '1600px',
        width: '100%',
    },
    imageContainer: {
        cursor: 'pointer',
    },
    imageLeft: {
        borderTopLeftRadius: '20px',
        borderBottomLeftRadius: '20px',
    },
    imageRight: {
        borderTopRightRadius: '20px',
        borderBottomRightRadius: '20px',
    },
    image: {
        width: '100%',
    },
    imageText: {
        margin: '5px 0',
        fontFamily: constantStyles.mainFontFamily,
        fontSize: '18px',
    },
};