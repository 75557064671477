import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Home from './pages/home';
import Featured from './pages/featured';
import New from './pages/new';
import Women from './pages/women';
import Men from './pages/men';
import Search from './pages/search';
import Insights from './pages/insights';
import Brands from './pages/brands';
import Likes from './pages/likes';
import About from './pages/about';
import Footer from './utils/footer';
import { constantStyles } from './constants/constantStyles';

function App() {
  return (
      <div style={{ lineHeight: 1 }}>
        <div style={styles.ContentWrapper}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/featured" element={<Featured />} />
          <Route path="/new" element={<New />} />
          <Route path="/women" element={<Women />} />
          <Route path="/men" element={<Men />} />
          <Route path="/search" element={<Search />} />
          <Route path="/insights" element={<Insights />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/likes" element={<Likes />} />
          <Route path="/info" element={<About />} />
        </Routes>
        </div>
      <Footer />
      </div>
  );
}
const styles = {
  ContentWrapper: {
    minHeight: constantStyles.minPageHeight,
  },
};

export default App;
