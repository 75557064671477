import React from 'react';
import ProductPage from '../product_page/productPage';

const Search = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchValue = urlParams.get('query');
    
    // Decode the value
    const decodedSearchValue = decodeURIComponent(searchValue);
    
    return <ProductPage page_title={`Search results for: ${decodedSearchValue}`}  initial_filters={{}}/>;
};

export default Search;